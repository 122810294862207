export const CARD_NAMES = {
  schofield: {
    it: 'Schofield'
  },
  volcanic: {
    it: 'Volcanic'
  },
  remington: {
    it: 'Remington'
  },
  rev_carabine: { it: 'Rev Carabine' },
  winchester: {
    it: 'Winchester'
  },
  bang: {
    it: 'BANG!'
  },
  mancato: {
    it: 'Mancato',
    en: 'Missed'
  },
  diligenza: {
    en: 'Diligence',
    it: 'Diligenza'
  },
  wells_fargo: {
    it: 'Wells Fargo'
  },
  birra: {
    it: 'Birra',
    en: 'Beer'
  },
  gatling: {
    en: 'Machine gun',
    it: 'Gatling'
  },
  panico: {
    en: 'Panic',
    it: 'Panico'
  },
  cat_balou: {
    it: 'Cat Balou'
  },
  saloon: {
    it: 'Saloon'
  },
  duello: {
    en: 'Duel',
    it: 'Duello'
  },
  emporio: {
    en: 'Emporium',
    it: 'Emporio'
  },
  indiani: {
    en: 'Indians',
    it: 'Indiani'
  },
  prigione: {
    en: 'Jail',
    it: 'Prigione'
  },
  dinamite: {
    en: 'Dynamite',
    it: 'Dinamite'
  },
  barile: {
    en: 'Barrel',
    it: 'Barile'
  },
  mirino: {
    it: 'Appaloosa'
  },
  mustang: {
    it: 'Mustang'
  }
}