import { render, staticRenderFns } from "./SpentCards.vue?vue&type=template&id=6a18c022&scoped=true&"
import script from "./SpentCards.vue?vue&type=script&lang=js&"
export * from "./SpentCards.vue?vue&type=script&lang=js&"
import style0 from "./SpentCards.vue?vue&type=style&index=0&id=6a18c022&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6a18c022",
  null
  
)

export default component.exports