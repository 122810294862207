import { render, staticRenderFns } from "./CardsButton.vue?vue&type=template&id=2ff301ab&scoped=true&"
var script = {}
import style0 from "./CardsButton.vue?vue&type=style&index=0&id=2ff301ab&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2ff301ab",
  null
  
)

export default component.exports